import {action, makeObservable, observable} from "mobx";
import React from "react";
import {getPrompt, updatePrompt} from "./requests";

class PromptStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    prompt = '';

    @observable
    error: string | null = null;

    @observable
    isLoading = false;

    @action
    onPromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.prompt = e.target.value;
        if (!this.prompt) {
            this.setError('Prompt skal angis')
        } else this.setError(null);
    };

    @action
    setError = (error: string | null) => this.error = error;

    @action
    init = () => {
        this.isLoading = true;
        this.setError(null);
        return getPrompt()
            .then(prompt => {
                this.prompt = prompt;
            })
            .catch((e: Error) => {
                this.setError(e.message)
            })
            .finally(() => this.isLoading = false);
    }

    @action
    update = async () => {
        this.isLoading = true;
        this.setError(null);
        updatePrompt(this.prompt)
            .then(prompt => {
                this.prompt = prompt;
            })
            .catch((e: Error) => {
                this.setError(e.message)
            })
            .finally(() => this.isLoading = false);
    }

    @action
    reset = async () => {
        this.isLoading = true;
        this.setError(null);
        updatePrompt(null)
            .then(prompt => {
                this.prompt = prompt;
            })
            .catch((e: Error) => {
                this.setError(e.message)
            })
            .finally(() => this.isLoading = false);
    }
}

export const promptStore = new PromptStore();
