import {Box, Button, LinearProgress, styled, TextField, Typography} from '@mui/material';
import { ArrowForwardIos as ArrowForwardIcon } from '@mui/icons-material';
import { observer } from 'mobx-react';
import {loginStore} from "../../store/loginStore";
import {PasswordField} from "../../components";

const Root = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    margin: '48px',
    alignItems: 'center',
});

const LoginArea = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    width: '50%',
})

export const LoginPage = observer(() => {
    const disabled = loginStore.isLoading;

    return (
            <Root>
                <Typography variant="h2">Velkommen til ZendeskGpt</Typography>
                <LoginArea>
                    <TextField
                        label="Brukernavn"
                        value={loginStore.login}
                        onChange={loginStore.onLoginChange}
                        disabled={disabled}
                        InputLabelProps={{ shrink: true }}
                        helperText={loginStore.error}
                        error={!!loginStore.error}
                        fullWidth
                    />
                    <PasswordField
                        autoComplete="off"
                        label="Passord"
                        value={loginStore.password}
                        onChange={loginStore.onPasswordChange}
                        disabled={disabled}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!loginStore.canSubmit || disabled}
                        onClick={loginStore.submit}
                        endIcon={<ArrowForwardIcon fontSize="large" />}
                    >
                        Logga in
                    </Button>
                    {loginStore.isLoading && <LinearProgress />}
                </LoginArea>
            </Root>
    );
});
