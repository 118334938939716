import { observer } from 'mobx-react';
import React, {FC} from 'react';

import {ContextDoc, Message} from "../../store/gpt.types";
import {Link, styled, Typography} from "@mui/material";
import {COLOR} from "../../theme/colors";
import {convertQuotes, MessagePart} from "./messageFormatter";

type Props = {
    message: Message;
};

const UserMessage = styled('div')({
    padding: '8px',
    borderRadius: 12,
    maxWidth: '66%',

    backgroundColor: COLOR.grayLight,
    borderBottomRightRadius: 0,
    alignSelf: 'flex-end',
    marginBottom: '2px',
});

const AIMessage = styled('div')({
    padding: '8px',
    borderRadius: 12,
    maxWidth: '66%',

    backgroundColor: COLOR.blueTransparent,
    borderBottomLeftRadius: 0,
    alignSelf: 'flex-start',
    marginBottom: '4px',
    display: 'flex',
    flexDirection: 'column'
});

const AIMessageText = styled(Typography)({
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
});

type OutputMessageProps = {
    message: MessagePart[];
};

const OutputMessage: FC<OutputMessageProps> = ({message}) => {
    return <>
        {message.map((m, i) => (m.url ? <span key={i.toString()}>{'['}<Link href={m.url} target="_blank">{m.text}</Link>{']'}</span> : <span key={i.toString()}>{m.text}</span>))}
    </>
}

type ContextInfoProps = {
    context: ContextDoc;
}

const ContextInfo: FC<ContextInfoProps> = ({context}) => {
    return <><br />{' - '}<Link href={context.url} target="_blank" >{context.title}</Link></>
}

export const GptMessage: FC<Props> = observer(({ message }) => {
    // const processedMessage = useMemo(() => convertQuotes(message.output, message.context || []), [message]);

    const processedMessage = convertQuotes(message.output, message.context || []);

    return (
        <>
            <UserMessage>
                <Typography variant="body1">{message.input}</Typography>
            </UserMessage>
            <AIMessage>
                <pre>
                    <AIMessageText variant="body1">
                        <OutputMessage message={processedMessage}/>
                        {!!message.context?.length && (<>
                            <br/>
                            {'Brukte artikler:'}
                            {message.context.map((c, i) => <ContextInfo context={c} key={i.toString()}/>)}
                            </>)}
                    </AIMessageText>
                </pre>
            </AIMessage>
        </>
    );
});
