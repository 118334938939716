import { observer } from 'mobx-react';
import React from 'react';
import { useEffect } from 'react';

import { GptMessage } from './GptMessage';
import {gptStore} from "../../store/gptStore";
import {Button, styled, Typography} from "@mui/material";
import {GptInput} from "./GptInput";

const Root = styled('div')({
    padding: '8px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
});

const Chat = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 4px',
    height: 'calc(100vh - 150px)',
    overflowY: 'scroll',
});

const Actions = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '12px',
})

const Input = styled('div')({
    height: 50,
    width: '100%',
});

const Disclaimer = styled('div')({
    textAlign: 'center',
    maxWidth: '1200px',
    margin: 'auto',
    marginBottom: '4px',
});

export const GptChat = observer(() => {
    const chatRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const lastMessage = gptStore.messages[gptStore.messages.length - 1];

    useEffect(() => {
        if (chatRef.current) chatRef.current.scrollTo({ top: chatRef.current.scrollHeight || 0 });
    }, [lastMessage?.output]);

    useEffect(() => {
        gptStore.init();
    }, []);

    return (
        <Root>
            <Chat ref={chatRef}>
                <Disclaimer>
                    <Typography color="textSecondary" variant="body1">
                        {'Send en melding for å få et råd eller tips'}
                    </Typography>
                </Disclaimer>
                {gptStore.messages.map((m, i) => (
                    <GptMessage key={i.toString()} message={m} />
                ))}
            </Chat>
            <Actions>
                <Button variant="contained" onClick={gptStore.resetChatId} disabled={gptStore.state !== 'idle'}>
                    {'Tilbakestille'}
                </Button>
                <Input>
                    <GptInput />
                </Input>
            </Actions>
        </Root>
    );
});
