import { action, computed, observable, makeObservable } from 'mobx';

import { getConversation, sendMessage } from './requests';
import type { Message } from './gpt.types';
import uuid from 'node-uuid';
import {authStore} from "./authStore";

type State = 'idle' | 'loading' | 'stream' | 'error';

const STREAM_TIMEOUT = 75;

const STORAGE_KEY = 'chatId';

class GptStore {
    constructor() {
        makeObservable(this);
    }

    chatId: string = '';

    @observable
    inputText: string = '';

    @action
    setInputText = (v: string) => {
        this.inputText = v;
    };

    @observable
    activeMessage: null | Message = null;

    @observable
    state: State = 'idle';

    historyMessages = observable.array<Message>([]);

    @computed
    get messages(): Message[] {
        const messages = this.historyMessages.slice();
        if (this.activeMessage) messages.push(this.activeMessage);

        return messages;
    }

    @action
    setMessages = (messages: Message[]) => {
        this.historyMessages.replace(messages.slice().reverse());
    };

    @action
    addMessage = (message: Message) => {
        this.historyMessages.push(message);
    };

    @action
    onLoading = () => {
        this.state = 'loading';
        this.activeMessage = { input: this.inputText, output: '...', context: [] };
    };

    @action
    onError = (error: Error) => {
        this.state = 'error';
        this.activeMessage = { input: this.inputText, output: 'Error', context: [] };

        throw error;
    };

    @action
    startStream = (message: Message) => {
        this.activeMessage = { ...message, output: '' };
        this.state = 'stream';
        this.inputText = '';
    };

    @action
    onStream = (word: string) => {
        if (!this.activeMessage) return;

        this.activeMessage.output += ' ' + word;
    };

    @action
    endStream = () => {
        if (!this.activeMessage) return;

        this.historyMessages.push(this.activeMessage);
        this.activeMessage = null;
        this.state = 'idle';
    };

    init = () => {
        let chatId = localStorage.getItem(STORAGE_KEY);

        if (!chatId) {
            chatId = this.generateNewChatId();
        }

        this.chatId = chatId;
        if (this.activeMessage) return;

        getConversation(chatId).then(this.setMessages).catch(() => authStore.logout());
    };

    sendMessage = async () => {
        if (!this.inputText || this.state !== 'idle') return;
        this.onLoading();

        try {
            const newMessage = await sendMessage(this.chatId, this.inputText);

            this.startStream(newMessage);

            const wordsReversed = newMessage.output.split(' ').reverse();

            const onNext = () => {
                const word = wordsReversed.pop();

                if (word) {
                    this.onStream(word);
                    setTimeout(onNext, STREAM_TIMEOUT);
                } else {
                    this.endStream();
                }
            };

            onNext();
        }
        catch (_e) {
            authStore.logout()
        };
    };

    generateNewChatId = () => {
        const chatId = uuid.v4();
        localStorage.setItem(STORAGE_KEY, chatId);

        return chatId;
    }

    @action
    resetChatId = () => {
        this.generateNewChatId();
        this.init();
    }
}

export const gptStore = new GptStore();
