import {baseApiUrl} from "../api/constants";
import {Message} from "./gpt.types";
import {authStore} from "./authStore";

export const mockApi = process.env.REACT_APP_MOCK_API?.toLowerCase() === 'true'

const defaultPrompt = 'Du er assistent for å svare på spørsmål om Bygglet. Som svar på det stilte spørsmålet, gi et samtalesvar ved hjelp av de innsendte fragmentene av artikler. Hvis du ikke vet svaret, si bare: "Jeg vet ikke." Forsøk ikke å finne på et svar. I svaret ditt angir du kun nummeret på fragmentet som svaret er basert på i hakeparentes. Hvis svaret kan være tvetydig, be om å få spørsmålet avklart.'

const getUrl = (path: string) => baseApiUrl + path;

const getHeaders = (withAuth: boolean = true) => {
    const headers: HeadersInit = {
        accept: 'application/json',
        'content-type': 'application/json',
    };

    if (withAuth) headers['authorization'] = `Bearer ${authStore.token}`;

    return headers;
};

export const sendMessage = async (chatId: string, message: string): Promise<Message> => {
    if (mockApi)
        return {
            input: 'What is integration?',
            output: 'Integration is [0] an important tool [2] to connect systems.',
            context: [
                {
                    title: 'First fragment',
                    url: 'http://google.com'
                },
                {
                    title: 'Second fragment',
                    url: 'http://google.com'
                },
                {
                    title: 'Third fragment',
                    url: 'http://google.com'
                }
            ]
        };

    const result = await fetch(getUrl('/send'), {
        method: 'post',
        headers: getHeaders(),
        body: JSON.stringify({
            chatId,
            message,
        }),
    });

    if (result.status !== 200) throw Error('Unauthorized');

    const resp = await result.json();

    return resp.message;
}

export const getConversation = async (chatId: string): Promise<Message[]> => {
    if (mockApi)
        return [];

    const result = await fetch(getUrl('/history'), {
        method: 'post',
        headers: getHeaders(),
        body: JSON.stringify({
            chatId,
        }),
    });

    if (result.status !== 200) throw Error('Unauthorized');

    return (await result.json()).messages;
}

export const login = async (login: string, password: string): Promise<string> => {
    if (mockApi)
        return 'token';

    const result = await fetch(getUrl('/login'), {
        method: 'post',
        headers: getHeaders(false),
        body: JSON.stringify({
            login,
            password,
        }),
    });

    if (result?.status !== 200) throw Error('Ugyldige opplysninger');

    const json = await result?.json();

    return json.token;
}

export const updatePrompt = async (prompt: string | null): Promise<string> => {
    if (mockApi)
        return prompt || defaultPrompt;

    const result = await fetch(getUrl('/updatePrompt'), {
        method: 'post',
        headers: getHeaders(),
        body: JSON.stringify({
            prompt,
        }),
    });

    if (result?.status !== 200) throw Error('Kan ikke oppdatere promptene');

    const json = await result?.json();

    return json.prompt;
}

export const getPrompt = async (): Promise<string> => {
    if (mockApi)
        return defaultPrompt;

    const result = await fetch(getUrl('/prompt'), {
        method: 'get',
        headers: getHeaders(),
    });

    if (result?.status !== 200) throw Error('Kan ikke hente prompt');

    const json = await result?.json();

    return json.prompt;
}

export const updateDb = async (): Promise<number> => {
    if (mockApi)
        return 0;

    const result = await fetch(getUrl('/updatedb'), {
        method: 'get',
        headers: getHeaders(),
    });

    if (result?.status !== 200) throw Error('Db-oppdateringsfeil');

    const json = await result?.json();

    return json.data;
}
